import React from 'react';

import { MainView, Text, Icon } from './styles.js'

export const Footer = () => {
  return (
    <MainView>
      <Text>Feito com<Icon style={{ fontSize: 15 }}/>por Gabriel Oliveira</Text>
    </MainView>
  );
}

 
