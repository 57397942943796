import './style.css'

function Experience() {
  return (
    <div className="experience">
      <div className="content">
        <img id="workImg" src="https://www.flaticon.com/svg/vstatic/svg/3143/3143004.svg?token=exp=1619649571~hmac=8600616dcc5eba29eda383bdabaf4022" alt="" />
        <h5>Aba em desenvolvimento, volte em breve para vê-la :)</h5>
      </div>
    </div>
  );
}

export default Experience;
